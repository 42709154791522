<template>
  <div>

    <el-container>
      <el-header style="background-color:#FFF;">
        <el-row class="pt-2 pb-2">
          <el-col :span="20">
          <h3 style="line-height:1.8">Über DACY</h3>
          </el-col>
          <el-col :span="2" style="line-height:50px; text-align:right">
            <el-button @click="$router.push({ name: 'dashboard' } )" class="mr-3 pl-3 pr-3 pt-2 pb-2" type="warning" icon="el-icon-s-home" ></el-button>
          </el-col>
          <el-col :span="2" class="text-right align-content-end">
            <main-menu></main-menu>
          </el-col>
        </el-row>
      </el-header>
      <div class="w-100" style="background-color:#f39200; height:8px;"></div>
      <el-main>
        <el-row >
          <el-col :span="24">

            <el-card>

              <div class="mb-3 w-75">
                Das primäre Ziel der Software Dacy ist die Unterstützung des Arztes bei der täglichen Arbeit am Patienten.<br/>
                Der Arzt wird unterstützt durch die schnelle und übersichtliche Verfügbarkeit von Informationen zum Patienten, seinen Diagnosen und den zugrunde liegenden Befunden, der Therapien  sowie der darüber hinausgehenden Therapieoptionen, der Dokumentation des Verlaufs und den aufgetretenen Nebenwirkungen bzw. Komplikationen.
                <br/>
                Die Vollständigkeit der Dokumentation wird angestrebt, ist jedoch nicht die primäre Absicht. Vielmehr soll der Arzt die Möglichkeit erhalten, Informationen in dem Moment, in dem sie anfallen, zeitsparend und strukturiert abzulegen.
                <br/><br/>
                Dacy soll dem Arzt zu einer besseren Übersicht über den Patienten im Behandlungsalltag verhelfen als auch die statistische Auswertung von Patientengruppen ermöglichen.
              </div>

              <el-row>
                <el-col :span="12">
                  TZN - Tumorzentrum Niederrhein GmbH<br/>
                  Am Hasenberg 44<br/>
                  41462 Neuss<br/>

                  <br/>
                  Telefon<br/>
                  Deutschland: 02131-2944609<br/>
                  International: +49 (0)2131- 2944609<br/>
                  Fax: 02131-2944611<br/>
                  <br/>
                  E-Mail<br/>
                  info@tz-nr.de<br/>
                  <br/>
                  Geschäftsführerin: Katrin Clasen-Losem<br/>
                  Verantwortlich für den Inhalt: Katrin Clasen-Losem<br/>
                  <br/>
                  Registergericht<br/>
                  AG Neuss
                  <br/>
                  <br/>
                  Handelsregister-Nummer HRB 15197<br/>
                  Umsatzsteuer-Identifikationsnummer DE266056001
                </el-col>
                <el-col :span="12">
                  Version {{dacyVersion}}
                  <br/><br/>
                  <el-button type="warning">Auf Updates prüfen</el-button>
                </el-col>
              </el-row>

            </el-card>


          </el-col>
        </el-row>
      </el-main>
    </el-container>

  </div></template>

<script>
import MainMenu from "@/components/parts/MainMenu";

export default {
  name: "About",
  props: [],
  components: {
    MainMenu
  },
  data () {
    return {
      dacyVersion: process.env.VUE_APP_DACY_VERSION
    }
  },
  mounted: function () {
  },
  computed: {
  },
  watch: {
  },
  methods: {
}

}
</script>
<style scoped>
</style>
